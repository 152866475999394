div.Submit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Submit button {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  padding: 6px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(166, 168, 5, 0.15);
  background-color: rgb(226, 226, 226);
  border: 0 solid;
  color: gray;
  margin-top: 40px;
  margin-bottom: 20px;
}

.Submit .header {
  height: 100px;
  margin: 20px;
  width: 50%;
  padding: 2rem;
  margin-bottom: 40px;
  margin-top: 0px;
}

.Submit .text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.Submit form {
  margin: 0px 20px 20px 0px;
  width: 50%;
  padding: 2rem;
}

@media (max-width: 848px) {
  div.Submit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    min-width: 594px;
  }

  .Submit .header {
    height: 100px;
    margin: 10px 30px;
    width: 100%;
    padding: 16px 16px 16px 8px;
  }

  .Submit .text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .Submit form {
    flex: 0.5;
    width: 100%;
  }
}

@media (min-width: 594px) and (max-width: 767px) {
  div.Submit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .Submit .header {
    height: 100px;
    margin: 10px 30px;
    width: 100%;
    padding: 16px 16px 16px 8px;
  }

  .Submit .text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .Submit form {
    flex: 0.5;
    width: 70%;
  }
}

@media (max-width: 593px) {
  div.Submit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .Submit .header {
    height: 100px;
    margin: 10px 30px;
    width: 100%;
    padding: 16px 16px 16px 8px;
  }

  .Submit .text {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .Submit form {
    flex: 0.5;
    width: 70%;
    padding-top: 16px;
  }
}
