.Faq{
    background-color: white;
    margin: 60px 0px; 
    padding-bottom: 30px;
    min-width:360px;
}


.Faq .faqHeader {
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.Faq p {
    margin: auto auto 1rem auto;
    height:37px;
    border: 1px solid gray; 
    border-radius: 100px; 
    font-size: 20px; 
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}

.Faq h2 {
    margin: auto auto 1rem;
    font-size: 32px; 
    font-weight: bold; 
}



@media (min-width: 568px) and (max-width: 767px) {
    .Faq h2 {
        margin: auto auto 1rem;
        font-size: 28px; 
        font-weight: bold; 
    }
}

@media (max-width: 567px) {
    .Faq h2 {
        margin: auto auto 1rem;
        font-size: 24px; 
        font-weight: bold; 
    }

}

