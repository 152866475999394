.Header {
    margin: auto;  
    padding-top: 80px; 
    padding-bottom: 80px; 
    display: flex; 
    gap: 70px; 
    background-color: #fcfcfd;
    background-image: url("/public/banner-bg.png");
    min-width: 360px; 
}



.Header h1 {
    font-size: 56px; 
    color: white;
}

.Header p {
    font-size: 20px;
    color: white; 
}

.Header b{
    color: #599ee9;
}

.header_cta button{
    margin-top: 30px; 
    background-color: #599ee9;
    color: white; 
    border: solid 1px rgb(20, 20, 20); 
}

.Header img {
    justify-content: flex-end;
    padding-left: 30px; 
    width: 450px; 
    height: 380px; 
}


@media (min-width: 1520px) {
    .Header{
        padding-left: 360px;
        padding-right: 180px;
    }
}

@media (min-width: 1120px) and (max-width: 1520px) {
    .Header{
        padding-left: 120px;
        padding-right: 60px;
    }
}

@media (min-width: 767px) and (max-width: 1120px) {
    .Header{
        padding-left: 80px;
        padding-right: 20px;
    }
}

@media (min-width: 568px) and (max-width: 767px) {
    .Header{
        padding-left: 40px;
        padding-right: 10px;
        gap: 50px;
    }
}

@media (min-width: 416px) and (max-width: 567px) {
    .Header{
        padding-left: 30px;
        padding-right: 10px;
        gap: 35px;
    }
    .Header h1 {
        font-size: 40px; 
        color: white;
    }
    
    .Header p {
        font-size: 16px;
        color: white; 
    }
}

@media (min-width: 396px) and (max-width: 415px) {
    .Header{
        padding-left: 20px;
        padding-right: 5px;
        gap: 30px;
    }
    .Header h1 {
        font-size: 36px; 
        color: white;
    }
    
    .Header p {
        font-size: 15px;
        color: white; 
    }
}

@media (max-width:395px) {
    .Header{
        padding-left: 20px;
        padding-right: 0px;
        gap: 30px;
    }
    .Header h1 {
        font-size: 32px; 
        color: white;
    }
    
    .Header p {
        font-size: 14px;
        color: white; 
    }

    .Header button {
        font-size: 16px; 
        margin-top: 10px; 
    }
}



@media (max-width: 1120px) {
    .Header img {
        width:0px; 
        height:0px; 
        visibility: hidden;
    }
}