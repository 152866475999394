@font-face {
    font-family: "NotoSans";
    src: url("/public/NotoSansKR-Regular.ttf");
}


body * {
    font-family: "NotoSans";
    color: #666666;
}
