.Slide{
    text-align:left; 
    max-width: 800px; 
    margin: 0 auto; 
    cursor: pointer; 

    min-width: 360px; 
}

.Slide .question{
    font-size: 18px; 
    margin: 5px 0px; 
    padding: 10px; 
}

.Slide .answer{
    font-size: 16px; 
    margin: 5px 0px; 
    padding: 0px 10px; 
    border: 1px solid rgb(236, 236, 236); 
}

.question {
    display: flex; 
    align-items: center;
}

.question .left {
    font-weight: bold; 
    justify-content: flex-start;
    width: 100%; 
}

.question .right {
    justify-content: flex-end;
    color: #666666;
}




@media (min-width: 568px) and (max-width: 767px) {
    .Slide .question{
        font-size: 16px; 
        margin: 5px 0px; 
        padding: 10px; 
    }
    
    .Slide .answer{
        font-size: 14px; 
        margin: 5px 0px; 
        padding: 0px 10px; 
        border: 1px solid rgb(236, 236, 236); 
    }
}

@media (min-width: 458px) and (max-width: 567px) {
    .Slide .question{
        font-size: 15px; 
        margin: 5px 0px; 
        padding: 10px; 
    }
    
    .Slide .answer{
        font-size: 13px; 
        margin: 5px 0px; 
        padding: 0px 10px; 
        border: 1px solid rgb(236, 236, 236); 
    }

}

@media (max-width: 457px) {
    .Slide .question{
        font-size: 14px; 
        margin: 5px 0px; 
        padding: 10px; 
    }
    
    .Slide .answer{
        font-size: 12px; 
        margin: 5px 0px; 
        padding: 0px 10px; 
        border: 1px solid rgb(236, 236, 236); 
    }
}
