.Section{
    margin: 0px; 
    padding: 80px 0px; 
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 320px;
    min-width: 360px; 
}

.Section h2 {
    font-weight: bold;
}

.Section p {
    text-align: center;
    margin: 0px; 
}

.Section .BodyPoint{
    height: 30px; 
    border: 1px solid gray; 
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    margin: auto auto 1rem; 
    width: 5rem; 
    /*
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    */
    font-size: 18px;
}


.Section img {
    width: 520px; 
    height: 464px; 
    margin: 40px; 
}



.Section2, .Section4 {
    margin-top:0px; 
    background-color: #fcfcfd; 
    border-radius: 5px;
}


.Section2 img, .Section4 img {
    border-radius: 5px;
}

.Section2 button, .Section4 button{
    margin-top: 30px; 
    background-color: #fcc657;
    color: rgb(30, 30, 30); 
    border: solid 1px rgb(200, 200, 200); 
}


.Section2 b, .Section4 b {
    color: #EEA120;
}

.Section3, .Section5 { 
    background-image: url("/public/banner-bg.png");
}

.Section3 h2, .Section5 h2 {
    color: white; 
}

.Section3 p, .Section5 p {
    color: white; 
}

.Section3 img, .Section5 img {
    border-radius: 5px;
}

.Section3 button, .Section5 button{
    margin-top: 30px; 
    background-color: #599ee9;
    color: white; 
    border: solid 1px rgb(20, 20, 20); 
}

.Section3 b, .Section5 b {
    color: #599ee9;
}


/*
@media (max-width: 520px) {
    .Section img{
        width: 100%; 
    }
}
*/






@media (min-width: 568px) and (max-width: 767px) {
    .Section img {
        width: 460px; 
        height: 410px; 
        margin: 30px; 
    }
}

@media (min-width: 458px) and (max-width: 567px) {
    .Section img {
        width: 400px; 
        height: 356px; 
        margin: 20px; 
    }
    .Section p {
        font-size: 14px; 
    }

    .Section h2 {
        font-size: 24px; 
    }
}

@media (min-width: 416px) and (max-width: 457px) {
    .Section img {
        width: 370px; 
        height: 329px; 
        margin: 15px; 
    }
    .Section p {
        font-size: 14px; 
    }

    .Section h2 {
        font-size: 24px; 
    }
}

@media (min-width: 396px) and (max-width: 415px) {
    .Section img {
        width: 350px; 
        height: 311px; 
        margin: 15px; 
    }
    .Section p {
        font-size: 14px; 
    }
    .Section h2 {
        font-size: 20px; 
    }
}

@media (max-width:395px) {
    .Section img {
        width: 320px; 
        height: 284px; 
        margin: 10px; 
    }

    .Section p {
        font-size: 12px; 
    }

    .Section h2 {
        font-size: 20px; 
    }

    .Section button {
        font-size: 16px; 
        margin-top: 10px; 
    }
}

