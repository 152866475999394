
.Footer {
    border-top: 1px solid rgb(246, 246, 246); 
    margin-top: 20px; 
    margin-bottom: 0px; 
    padding: 10px 0px; 
    background-color: #1e2836;
    min-width:360px;
}

.Footer ul {
    padding: 0px; 
}

.Footer li  {
    list-style: none;
    text-align: center;
    color: rgb(180, 180, 180); 
}


